// app/javascript/controllers/page_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hours", "minutes", "seconds", "countdownSection", "playerSection", "adviceItem"]
  
  static values = {
    startTime: String
  }
  connect() {
    // console.log("Page controller connected")
    // this.startCountdown()
    // this.rotateAdvice()
  }

  startCountdown() {
    setInterval(() => {
      const now = new Date()
      
      console.log(this.startTimeValue, Date.parse(this.startTimeValue));
      const diff = Date.parse(this.startTimeValue) - now

      if (diff > 0) {
        const hours = Math.floor(diff / 3600000).toString().padStart(2, '0')
        const minutes = Math.floor((diff % 3600000) / 60000).toString().padStart(2, '0')
        const seconds = Math.floor((diff % 60000) / 1000).toString().padStart(2, '0')

        this.hoursTarget.textContent = hours
        this.minutesTarget.textContent = minutes
        this.secondsTarget.textContent = seconds
      } else {
        this.switchToPlayer()
      }
    }, 1000)
  }

  rotateAdvice() {
    let currentAdvice = 0
    this.showAdvice(currentAdvice)

    setInterval(() => {
      this.hideAdvice(currentAdvice)
      currentAdvice = (currentAdvice + 1) % this.adviceItemTargets.length
      this.showAdvice(currentAdvice)
    }, 5000)
  }

  showAdvice(index) {
    this.adviceItemTargets[index].classList.remove('hidden')
  }

  hideAdvice(index) {
    this.adviceItemTargets[index].classList.add('hidden')
  }

  switchToPlayer() {
    this.countdownSectionTargets.forEach(el => el.classList.add('hidden'))
    this.playerSectionTarget.classList.remove('hidden')
    window.location.reload(1);
  }
}