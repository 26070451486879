// magic
import Rails from '@rails/ujs';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import "./ahoy"

// RESOURCES
var timestamp = function() {
	return Date.now()
}, 
loadSessionData = function(){
  if (typeof session_data !== 'undefined'){
    window.session_data = session_data;
    window.player_preference_data = player_preference_data;
  
    // console.log(window.session_data, window.player_preference_data)
  }
}

var 
statusReport, // overridable function to provide current playback state
sessionUUID,
interval_id,
video_interval_id, 
startTime,
interval_fail_count,
cookie_before,
loadPlayerAuth = function(){
  startTime = Date.now();
  // find user id data from the session-data node
  // call verification endpoint to keep-alive
  cookie_before=false; // Cookies.get(session_data.user_id+"-"+session_data.fixture_id);
  // console.log("cookie set by server is "+cookie_before);
  callAuthenticationEndpoint(window.session_data);
},
loadPlayer = function(){
  //using var player_preference_data
  // console.log("Using", player_preference_data);
}, 
loadUniq = function(){
  sessionUUID=uuidv4();
  window.session_data.uuid = sessionUUID;
},
callAuthenticationEndpoint = function(data){
  data_str = ''
  
  for (const [k, v] of Object.entries(data)) {
    // console.log(`${key}: ${value}`);
    data_str += `session[${k}]=${v}&`
  }
  // data_str = 'session[user_id]='+data.user_id+'&session[fixture_id]='+data.fixture_id
  Rails.ajax({
    type: "POST",
    headers: { 'X-CSRF-Token': Rails.csrfToken() },
    url: '/session/keepalive',
    data: data_str,
    // dataType:'json',
    error: function (xhr, status, error) {
      clearInterval(interval_id);
    },
    success: function (data) { 
      // find_payment_for_fixture
      if(!cookie_before){
        cookie_before = data.status.auth_cookie;
        // console.log('new cookie');
      } else if(data.status.auth_cookie != cookie_before){
        window.location = '/?reason=multiple'; // redirect
        // alert("You may only view each stream from one location. If you feel this message is in error, change your account password and get in touch with support.");
      } else if(data.status.logged_in==false || data.status.user_id==-1){
        window.location = '/?reason=logged-out'; // redirect
        // alert("You have been logged out");
      } else {
        // console.log('Cookie validated');
      }

      return true;
    }
  });
},

trackVideoPlaybackInfo = function(uuid, p_session_data, p_player_data){
  var startTime = timestamp();
  if (typeof window.statusReport !== "undefined" || typeof window.playbackTime !== "undefined") { 
    video_interval_id=setInterval(function(){
      sr=window.statusReport();
      ahoy.track('Playback', {session: p_session_data ,userId: p_session_data.user_id, playerTech: "JWPlayer", status: sr, playbackTime: timestamp()-startTime});
    }, (1000 * 120)); 
  } else {
    ahoy.track('Playback', {session: p_session_data ,userId: p_session_data.user_id, playerTech: "JWPlayer", status: "Started - no status or playbacktime methods defined", playbackTime: 0});
  }
};

window.statusReport = function(){
  "--"
  // player()
}

// window.checkStatusOfPlaybackURL = checkStatusOfPlaybackURL;

// Event

loadSessionData();
if (typeof window.session_data !== 'undefined'){
  if (loadPlayerAuth()) loadPlayer();

  /* load this data from dom */
  trackVideoPlaybackInfo(loadUniq(), window.session_data, player_preference_data);

  interval_id=setInterval(function() {
    if(window.session_data.status=="active"){ callAuthenticationEndpoint(window.session_data); }
  }, (1000 * 60 * 3));

}