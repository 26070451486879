import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'; // or .min.js
import "./ahoy"; // Assuming ahoy is imported the same way as in your webview script

// Wrap everything in an immediately-invoked function expression (IIFE)
(function runTimezoneConversion() {
  try {
    // First check if there are any time wrappers at all before doing any processing
    const timeWrappers = document.querySelectorAll('.time-wrapper');
    
    // Early exit if no time wrappers exist
    if (!timeWrappers || timeWrappers.length === 0) {
      return; // Now this return is inside a function, so it's valid
    }

    // Determine user timezone once (outside the loop for efficiency)
    const userTimeZone = moment.tz.guess(true);
    
    // Early exit if user is already in London timezone - no conversions needed
    if (userTimeZone === "Europe/London") {
      // Optionally log a minimal message that no conversions were needed
    //   if (typeof ahoy !== 'undefined') {
    //     ahoy.track('Timezone No Action', {
    //       reason: 'User already in Europe/London timezone',
    //       wrapperCount: timeWrappers.length
    //     });
    //   }
    
      return;
    }
    
    // Initialize tracking bundles - only if we need to do conversions
    const trackingData = {
      conversions: [],
      errors: [],
      missingElements: []
    };

    // Check for valid time elements before processing
    let hasValidElements = false;
    
    // First pass - quick check to see if we have any valid elements to process
    for (let i = 0; i < Math.min(timeWrappers.length, 5); i++) {
      const localTimeElement = timeWrappers[i].querySelector('.time-local');
      const remoteTimeElement = timeWrappers[i].querySelector('.time-remote');
      
      if (localTimeElement && remoteTimeElement && localTimeElement.dataset.timestamp) {
        hasValidElements = true;
        break;
      }
    }
    
    // Early exit if no valid elements found in sample
    if (!hasValidElements) {
      if (typeof ahoy !== 'undefined') {
        ahoy.track('Timezone No Action', {
          reason: 'No valid time elements found in sample',
          wrapperCount: timeWrappers.length
        });
      }
      return;
    }

    // Process all elements only if we found valid elements in our sample
    timeWrappers.forEach((timeWrapper, index) => {
      try {
        const localTimeElement = timeWrapper.querySelector('.time-local');
        const remoteTimeElement = timeWrapper.querySelector('.time-remote');
        
        // Skip this element if it's not properly structured
        if (!localTimeElement || !remoteTimeElement || !localTimeElement.dataset.timestamp) {
          if (localTimeElement || remoteTimeElement) {
            // Only track if at least one element exists (indicates partial setup)
            trackingData.missingElements.push({
              index,
              hasLocalTime: !!localTimeElement,
              hasRemoteTime: !!remoteTimeElement,
              hasTimestamp: !!(localTimeElement && localTimeElement.dataset.timestamp)
            });
          }
          return; // Skip to next element
        }
        
        const localTime = parseInt(localTimeElement.dataset.timestamp) * 1000;
        
        // Skip invalid timestamps
        if (isNaN(localTime)) {
          trackingData.errors.push({
            index,
            error: `Invalid timestamp: ${localTimeElement.dataset.timestamp}`
          });
          return; // Skip to next element
        }
        
        // Process the conversion
        try {
          const m = moment(localTime).tz(userTimeZone);
          
          // Skip invalid moment objects
          if (!m.isValid()) {
            trackingData.errors.push({
              index,
              error: `Invalid moment object for timestamp: ${localTime}`
            });
            return; // Skip to next element
          }
          
          const timeZoneObj = moment.tz.zone(userTimeZone);
          
          // Skip invalid timezones
          if (!timeZoneObj) {
            trackingData.errors.push({
              index,
              error: `Invalid timezone: ${userTimeZone}`
            });
            return; // Skip to next element
          }
          
          const mtz = timeZoneObj.abbr(m);
          remoteTimeElement.textContent = `Approximately ${m.format("HH:mm")} ${mtz}`;
          
          // Add a data attribute to track successful conversions
          remoteTimeElement.dataset.converted = 'true';
          
          // Only track detailed info for the first 10 conversions
          if (trackingData.conversions.length < 10) {
            trackingData.conversions.push({
              index,
              from: "Europe/London",
              to: userTimeZone,
              localTime: localTimeElement.dataset.timestamp,
              convertedTime: m.format("HH:mm"),
              abbreviation: mtz
            });
          } else {
            // Just count the rest
            trackingData.conversionsCount = (trackingData.conversionsCount || 10) + 1;
          }
        } catch (conversionError) {
          trackingData.errors.push({
            index,
            error: conversionError.toString()
          });
        }
      } catch (elementError) {
        // Only record first 10 errors for detailed reporting
        if (trackingData.errors.length < 10) {
          trackingData.errors.push({
            index,
            error: elementError.toString()
          });
        } else {
          // Just count the rest
          trackingData.errorsCount = (trackingData.errorsCount || 10) + 1;
        }
      }
    });
    
    // Only send tracking data if we have meaningful information
    if (typeof ahoy !== 'undefined' && 
        (trackingData.conversions.length > 0 || 
         trackingData.errors.length > 0 || 
         trackingData.missingElements.length > 0)) {
      
      // Send summary data
      ahoy.track('Timezone Conversions Summary', {
        timezone: userTimeZone,
        totalWrappers: timeWrappers.length,
        conversions: trackingData.conversions.length + (trackingData.conversionsCount || 0),
        errors: trackingData.errors.length + (trackingData.errorsCount || 0),
        missingElements: trackingData.missingElements.length,
        detailedConversions: trackingData.conversions,
        detailedErrors: trackingData.errors.slice(0, 5),
        detailedMissing: trackingData.missingElements.slice(0, 5)
      });
    }
  } catch (e) {
    // Main error tracking for the entire script - only send if it's a fatal error
    console.error("Timezone conversion error:", e);
    
    if (typeof ahoy !== 'undefined') {
      ahoy.track('Timezone Script Fatal Error', {
        error: e.toString(),
        stack: e.stack,
        userAgent: navigator.userAgent
      });
    }
  }
})(); // Execute the function immediately